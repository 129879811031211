<template>
  <div class="about-us" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" id="pdfDom">
      <div class="table" :class="{'pdfheight': isPrint}">
        <h1 class="title">
          <b v-if="pageId==1">【家庭功能现状】
            <br>
            <br>家庭报告
          </b>
          <b v-else>【家庭功能现状】
            <br>
            <br>家庭报告
          </b>
        </h1>
        <div class="author">
          <p>杨敦雄 编制</p>
        </div>
        <!-- 量表介紹 -->
        <p class="title3 tx-l">量表介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>
              <p
                class="tips tx-idt2"
              >
            本量表用于了解家庭功能实现水平，为进一步提供关于如何促进儿童发展的家庭教育方式。
                </p>
            </th>
          </tr>
        </table>
        <div class="head-title">
          <p class="title3 tx-l">基本资料</p>
          <p class="title3 tx-l">家庭编号：{{baseInfo.sn}}</p>
        </div>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="140px">受访者姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.name">
              </div>
            </td>
            <th width="140px">版本</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.edition">
              </div>
            </td>
          </tr>
          <tr>
            <th>受访者性别</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.sex">
              </div>
            </td>
           
            <th>受访者出生日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.birthday">
              </div>
            </td>
          
          </tr>
          <tr>
            <th>受访者出生地</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.father_town">
              </div>
            </td>
            <th>受访者教育程度</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.father_edu">
              </div>
            </td>
            
          </tr>
          <tr>
            <th>受访者联系方式</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.mobile">
              </div>
            </td>
            <th>配偶实足年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.mother_age">
              </div>
            </td>
          </tr>
          <tr>
            <th>配偶出生地</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.mother_town">
              </div>
            </td>
            <th>配偶教育程度</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.mother_edu">
              </div>
            </td>
          </tr>
          <tr>
            <th>育儿数量</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.rank">
              </div>
            </td>
            <th>子女身心发展</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.body">
              </div>
            </td>
          </tr>
         
          <tr>
            <th>儿童出生日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.father_age">
              </div>
            </td>
            <th>主要照顾者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.care_man">
              </div>
            </td>
          </tr>

          <tr>
            <th>家中主要经济来源</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.income">
              </div>
            </td>
            <th>家庭型态</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.family">
              </div>
            </td>
          </tr>
        </table>
        <!-- 家庭资料 -->
        <p class="title3 tx-l">家庭资料</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="140px">母亲实足年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.mother_age"/>
              </div>
            </td>
            <th width="140px">父亲实足年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.father_age"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>母亲出生地</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.mother_town"/>
              </div>
            </td>
            <th>父亲出生地</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.father_town"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>母亲教育程度</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.mother_edu"/>
              </div>
            </td>
            <th>父亲教育程度</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.father_edu"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>家中主要经济来源者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.income"/>
              </div>
            </td>
            <th>家中主要使用语言</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.language"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>主要照顾者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.care_man"/>
              </div>
            </td>
            <th>家庭型态</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.family"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>居住地区</th>
            <td colspan="3">
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.address"/>
              </div>
            </td>
          </tr>
        </table>
       
        <p class="title3 tx-l">本量表结果</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>向度</th>
            <th>百分等级</th>
            <th>结果</th>
          </tr>
          <tr>
            <td width="17%" class="tx-c">家庭功能现状</td>
            <td width="33%">
              <p class="tx-c">{{total.percent}}</p>
            </td>
            <td v-if="total.percent>=75" class="tx-c">家庭功能实现优异</td>
            <td v-else-if="total.percent>=50" class="tx-c">家庭功能实现较好</td>
            <td v-else-if="total.percent>=25" class="tx-c">家庭功能实现较差</td>
            <td v-else-if="total.percent>=0" class="tx-c">家庭功能未实现</td>
            <td v-else class="tx-c">属正常范围。</td>
          </tr>
        </table>
        <!-- 百分等级分数说明 -->
        <p class="title3 tx-l">百分等级分数说明</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th colspan="4">
              <p class="tips">1. 百分等级在75以上属家庭功能实现优异。
                <br>2. 百分等级在50-74之间属家庭功能实现较好。
                <br>3. 百分等级在25-49之间属家庭功能实现较差。
                <br>4. 百分等级在25以下者属家庭功能未实现。
              </p>
            </th>
          </tr>
        </table>
        <!-- 向度分析 -->
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <p class="title3 tx-l">向度分析</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="20%">向度</th>
            <th>向度说明</th>
            <th>原始分数</th>
            <th>百分等级</th>
          </tr>
          <tr v-for="(list, index) in cateScore" :key="index">
            <td class="gray">{{list.cate_name}}</td>
            <td>
              <p>{{list.describe}}</p>
            </td>
            <td class="tx-c">{{list.score}}</td>
            <td class="tx-c">{{list.percent}}</td>
          </tr>
        </table>
        <!-- 剖面图 -->
        <p class="title3 tx-l">家庭功能侧面图</p>
        <div class="charts" style="height: 600px;" id="charts1"></div>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <!-- 综合分析 -->
        <!-- <p class="title3 tx-l">综合分析</p>
        <p class="title3 tx-l" v-if="weak.uncertain.length">◆功能未实现项目</p>
        <div class="tips" v-if="weak.uncertain.length">
          儿童在
          <span v-for="(list, index) in weak.uncertain" :key="index">
            <b class="">{{list.cate_name}}</b>
            <span v-if="index!=weak.uncertain.length-1">、</span>
          </span>
          达到百分等级85以上，属于疑似障碍。建议一至三个月之后再次进行评估，以确认其状况是否属于障碍，或是针对该向度进行感觉统合游戏训练以增进儿童感觉统合能力。
        </div>
        <p class="title3 tx-l" v-if="weak.certain.length">◆ 障碍项目</p> -->
        <!-- <div class="tips" v-if="weak.certain.length">
          儿童在
          <span v-for="(list, index) in weak.certain" :key="index">
            <b class="">{{list.cate_name}}</b>
            <span v-if="index!=weak.certain.length-1">、</span>
          </span>
          达到百分等级95以上，可能是感觉统合障碍或受到其他因素影响造成障碍，宜尽速鉴定、转介与治疗。
        </div> -->
        <!-- ◆	解释与建议 -->
        <p class="title3 tx-l">◆ 解释与建议</p>
        <p class="title3 tx-l">◆ 解释与建议</p>
        <div class="tips">
          <div v-for="(item, index) in weak.suggest" :key="index" v-if="index<100">
            <p class="tx-idt2" style="line-height: 1.6;">{{item.desc}}</p>
            <br>
          </div>
          <!--<p class="tx-idt2" v-for="(item, index) in weak.uncertain" :key="index">{{item.desc}}</p>-->
          <p class="tx-idt2" style="line-height: 1.6;" v-if="weak.suggest.length<100">
            家庭功能的实现通过潜移默化的方式影响着儿童的发展。该测评必须结合儿童身心发展报告和家庭管理服务系统相结合。
          </p>
        </div>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <div class="tips" v-if="weak.suggest.length>=5">
          <div v-for="(item, index) in weak.suggest" :key="index" v-if="index>=100">
            <p class="tx-idt2" style="line-height: 1.6;">{{item.desc}}</p>
            <br>
          </div>
          <!--<p class="tx-idt2" v-for="(item, index) in weak.uncertain" :key="index">{{item.desc}}</p>-->
          <p  class="tx-idt2" style="line-height: 1.6;" v-if="weak.suggest.length>=100">
            家庭功能的实现通过潜移默化的方式影响着儿童的发展。该测评必须结合儿童身心发展报告和家庭管理服务系统相结合。</p>
        </div>
       <!--<p class="title3 tx-l">◆ 备注</p>
        <div class="tips">
          <p class="">如果您对本测验结果或解释有任何疑问，或是想进一步了解的地方，请与施测人员共同澄清讨论。</p>
        </div>
        <br>
        -->
        <p class="title3 tx-l">◆ 补充说明</p>
        <div class="tips">
          <p class="">{{baseInfo.remark}}</p>
        </div>
        
      </div>
    </div>
    <button class="print" v-on:click="printPdf">打印</button>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
import echarts from "echarts";
export default {
  name: "aboutus",
  data() {
    return {
      baseInfo: {},
      cateScore: [],
      high: [],
      highest: [],
      weak: {},
      total: "",
      isPrint: false,
      pageId: ""
    };
  },
  components: {
    HeaderNav,
    FooterNav,
    echarts
  },
  methods: {
    getData() {
      let params = {
        exam_id: this.$route.query.eid
      };
      this.$post("report/getReport", params).then(res => {
        if (res.code === 1) {
          this.baseInfo = res.data.base_info;
          this.cateScore = res.data.cate_score;
          let index = this.cateScore.length - 1;
          this.total = this.cateScore[index];
          this.weak = res.data.weak;
          this.$nextTick(() => {
            this.drawLine();
          });
          this.$nextTick(() => {
            if (this.$route.query.print) {
              this.printPdf();
            }
          });
        } else {
          this.$layer.msg(res.msg);
        }
      });
    },
    drawLine() {
      let cateScore = this.cateScore;
      let myChart = echarts.init(document.getElementById("charts1"));
      let xdata = [];
      let ydata = [];
      for (let i = 0; i < cateScore.length; i++) {
        xdata.push(cateScore[i].cate_name);
        ydata.push(cateScore[i].percent);
      }
      myChart.setOption({
        grid: {
          top: '2%',
          left: '4%',
          right: '4%',
          bottom: "8%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisLabel: {
            textStyle: {
              fontSize: "16",
              color: '#3e333d'
            },
            interval: 0,
            formatter: function(value) {
              //x轴的文字改为竖版显示
              var str = value.split("");
              return str.join("\n");
            }
          }
        },
        yAxis: [
          {
            name: "百\n分\n等\n级",
            type: "value",
            max: 99,
            min: 0,
            splitNumber: 20,
            nameLocation: "middle",
            nameRotate: 0,
            nameGap: 30,
            nameTextStyle: {
              fontSize: 18
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#664c69"],
                width: 1,
                type: "dotted"
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff','#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#b8aab7', '#b8aab7', '#a28aa0']
              }
            }
          },
          {
            name: "百\n分\n等\n级",
            type: "value",
            max: 99,
            min: 0,
            splitNumber: 20,
            nameLocation: "middle",
            nameRotate: 0,
            nameGap: 30,
            nameTextStyle: {
              fontSize: 18
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            data: ydata,
            textStyle: {
              fontSize: 16
            },
            type: "line",
            lineStyle: {
              color: "#3e333d"
            }
          }
        ]
      });
    },
    printPdf() {
      this.isPrint = true;
      setTimeout(() => {
        this.getPdf("家庭功能现状", () => {
          this.isPrint = false;
        });
      }, 1500);
    }
  },
  mounted() {},
  created() {
    this.getData();
    this.pageId = this.$route.query.id;
  }
};
</script>

<style lang="less" scoped>
  @import url("../../assets/css/page/result");
</style>
